html, body {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: 'Arimo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.6rem;
}